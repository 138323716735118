// @mui
import { enUS, frFR, zhCN, viVN, arSD } from '@mui/material/locale'

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
    MOBILE_HEIGHT: 64,
    MAIN_DESKTOP_HEIGHT: 88,
    DASHBOARD_DESKTOP_HEIGHT: 92,
    DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32
}

export const NAVBAR = {
    BASE_WIDTH: 260,
    DASHBOARD_WIDTH: 280,
    DASHBOARD_COLLAPSE_WIDTH: 88,
    //
    DASHBOARD_ITEM_ROOT_HEIGHT: 48,
    DASHBOARD_ITEM_SUB_HEIGHT: 40,
    DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32
}

export const ICON = {
    NAVBAR_ITEM: 22,
    NAVBAR_ITEM_HORIZONTAL: 20
}

// SETTINGS
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const defaultSettings = {
    themeMode: 'dark',
    themeDirection: 'ltr',
    themeContrast: 'default',
    themeLayout: 'horizontal',
    themeColorPresets: 'blue',
    themeStretch: false
}

// MULTI LANGUAGES
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const allLangs = [
    {
        label: 'English',
        value: 'en',
        systemValue: enUS,
        icon: '/assets/icons/flags/ic_flag_en.svg'
    },
    {
        label: 'French',
        value: 'fr',
        systemValue: frFR,
        icon: '/assets/icons/flags/ic_flag_fr.svg'
    },
    {
        label: 'Vietnamese',
        value: 'vn',
        systemValue: viVN,
        icon: '/assets/icons/flags/ic_flag_vn.svg'
    },
    {
        label: 'Chinese',
        value: 'cn',
        systemValue: zhCN,
        icon: '/assets/icons/flags/ic_flag_cn.svg'
    },
    {
        label: 'Arabic (Sudan)',
        value: 'ar',
        systemValue: arSD,
        icon: '/assets/icons/flags/ic_flag_sa.svg'
    }
]

export const defaultLang = allLangs[0] // English
