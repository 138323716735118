import _ from 'lodash'
import { Grid } from '@mui/material'
import { Box, Card, CardContent } from '@mui/material'
import {
    Apartment as ApartmentIcon,
    QuestionAnswer as QuestionAnswerIcon,
    Person3 as Person3Icon
} from '@mui/icons-material'
import { getFormattedDate, COMPANY, PERSON, CONVERSATION } from 'utils/core'

export function RelatedHitCard({ type = CONVERSATION, id = '', hit, currentlySelectedId }) {
    const isPerson = type === PERSON
    const isCompany = type === COMPANY
    const isConversation = type === CONVERSATION

    const renderPersonContent = (hit) => {
        return (
            <>
                <div style={{ fontWeight: 'bolder' }}>
                    {_.truncate(hit.person.name, { length: 24 })}{' '}
                </div>
                <div
                    style={{
                        color: currentlySelectedId === id ? 'white' : 'grey',
                        fontWeight: 'bold'
                    }}>
                    {hit?.person?.socials?.email}
                </div>
            </>
        )
    }

    const renderCompanyContent = (hit) => {
        return (
            <>
                <div style={{ fontWeight: 'bolder' }}>{_.capitalize(hit.name)}</div>
                <div
                    style={{
                        color: currentlySelectedId === id ? 'white' : 'grey',
                        fontWeight: 'bold'
                    }}>
                    {hit?.companyRoot}
                </div>
            </>
        )
    }

    const renderConversationContent = (hit) => {
        return (
            <>
                <div style={{ fontWeight: 'bolder' }}>{_.capitalize(hit.name)}</div>
                <div
                    style={{
                        color: currentlySelectedId === id ? 'white' : 'grey',
                        fontWeight: 'bold'
                    }}>
                    {getFormattedDate(hit.emailSentDate)}
                </div>
            </>
        )
    }
    return (
        <Card
            sx={{
                minWidth: '300px',
                maxWidth: '300px',
                borderRadius: 2,
                m: 1,
                ml: 0,
                p: 1,
                mb: 2,
                border: currentlySelectedId === id ? '5px solid white' : '1px solid white',
                transition: 'all .2s ease-in-out',
                ':hover': {
                    border: currentlySelectedId === id ? '5px solid white' : '1px solid white',
                    color: 'white'
                },
                cursor: 'pointer',
                background: 'linear-gradient(to right, #2c3e50, #202e3c)'
            }}
            onClick={() => window.open(`${window.location.origin}/details/${id}`, '_self')}>
            <CardContent sx={{ p: 1, ':last-child': { pb: '8px' } }}>
                <Grid
                    container
                    sx={{ display: 'grid', gridTemplateColumns: 'fit-content(100px) 1fr' }}>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ pb: 0, pr: 2 }}>
                        {isPerson && <Person3Icon sx={{ color: 'white' }} />}
                        {isCompany && <ApartmentIcon sx={{ color: '#009EDB' }} />}
                        {isConversation && <QuestionAnswerIcon sx={{ color: '#e1cd1b' }} />}
                    </Box>
                    <Grid
                        container
                        sx={{
                            display: 'grid',
                            gridTemplateRows: 'fit-content(40px) fit-content(40px)'
                        }}>
                        {isPerson && renderPersonContent(hit)}
                        {isCompany && renderCompanyContent(hit)}
                        {isConversation && renderConversationContent(hit)}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}
