import React from 'react'
//import HitCard from './HitCard'
import HitCard from '../components/hit-card/HitCard'
import Pagination from '@material-ui/lab/Pagination'
import { useSelector, useDispatch } from 'react-redux'
import { fetchTagResults } from '../redux/appSlice'

const ListTagResults = ({ hitsList, uniqueKey, setPreviewRecord, userTag, setUpdateLists }) => {
    const dispatch = useDispatch()
    const tagResults = useSelector((state) => state.app.tagResults)

    return (
        <ul className="ais-Hits-list-tagView">
            <li key={`${uniqueKey}-Title`} className="tagTitle">
                {`${userTag}-${uniqueKey}`}
            </li>
            {hitsList.map((hit) => (
                <li key={hit.objectID} className="tagBody">
                    <HitCard
                        hit={hit}
                        setPreviewRecord={setPreviewRecord}
                        userTag={userTag}
                        uniqueKey={uniqueKey}
                        setUpdateLists={setUpdateLists}
                    />
                </li>
            ))}

            {tagResults[uniqueKey].pages > 1 ? (
                <Pagination
                    count={tagResults[uniqueKey].pages}
                    variant="outlined"
                    shape="rounded"
                    onClick={(event) => {
                        const page = parseInt(event.target.innerText) - 1
                        const p = uniqueKey
                        dispatch(fetchTagResults({ userTag, page, p, tagResults }))
                    }}
                    hideNextButton={true}
                    hidePrevButton={true}
                />
            ) : null}
        </ul>
    )
}

export default ListTagResults
