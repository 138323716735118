import React, { useEffect } from 'react'
import ListTagResults from './ListTagResults.js'
import { useSelector, useDispatch } from 'react-redux'
import { fetchTagResults } from '../redux/appSlice'

const TAG_KEYS = {
    p1: 'p1',
    p2: 'p2',
    p3: 'p3'
}

const TagsHits = ({ setPreviewRecord }) => {
    const tagResults = useSelector((state) => state.app.tagResults)
    const userTag = useSelector((state) => state.app.userTag)
    const dispatch = useDispatch()

    useEffect(() => {
        let userTagParam
        const url = window.location.href
        if (url.split('?')) {
            const userTagTuple = url.split('?')[1] ? url.split('?')[1] : ''
            userTagParam = userTagTuple.split('=')[1]
        }
        if (userTag && tagResults && tagResults.p1) {
            return
        }
        if ((userTag && !tagResults) || (userTag && userTag !== userTagParam)) {
            dispatch(fetchTagResults({ userTag }))
        }
    }, [tagResults, userTag])

    if (!tagResults) return null

    return (
        <div className="ais-Hits">
            {Object.keys(TAG_KEYS).map((tagKey, idx) => (
                <ListTagResults
                    key={`${tagKey}-${idx}`}
                    hitsList={tagResults[tagKey].hits}
                    uniqueKey={tagKey}
                    setPreviewRecord={setPreviewRecord}
                    userTag={userTag}
                    pages={tagResults[tagKey].pages}
                />
            ))}
        </div>
    )
}

export default TagsHits
