import * as React from 'react'
import moment from 'moment'
import { DataGridPro } from '@mui/x-data-grid-pro'
import Box from '@mui/material/Box'
import ClipLoader from 'react-spinners/ClipLoader'
import { LicenseInfo } from '@mui/x-license-pro'
import CryptoJS from 'crypto-js'

/** Required for x-data-grid-pro. Check link: https://mui.com/x/introduction/licensing/#license-key-installation */
LicenseInfo.setLicenseKey(
    'a47d12561dc514cebd7f3d25351a78aeTz01MzkyNCxFPTE2OTk0NzkxNDQ5NDEsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
)

export function DataGridTable({ data, props = {}, sortable = 'yes' }) {
    if (!data || data.length === 0) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <ClipLoader color="#36d7b7" />
            </Box>
        )
    }

    const handleCellClick = (params) => {}

    const columns = Object.keys(data[0]).map((col) => {
        let column = {
            field: col,
            headerName: col,
            flex: Object.keys(data[0]).length + 1,
            headerClassName: 'super-app-theme--header',
            cellClassName: 'super-app-theme--cell',
            editable: false,
            hide: col === 'id' ? true : false,
            sortable: sortable === 'yes' ? true : false
        }
        if (col === 'Last Interaction' || col === 'Date') {
            column = {
                ...column,
                valueFormatter: (params) => {
                    console.log(params?.value)
                    const value = moment(params?.value).format('MM/DD/YYYY')
                    console.log(value)
                    return value
                }
            }
        }
        if (
            col === 'Closest Contact' ||
            col === 'First Contact' ||
            col === 'Latest Contact' ||
            col === 'Email' ||
            col === 'From' ||
            col === 'To'
        ) {
            column = {
                ...column,
                renderCell: (params) => (
                    <a
                        rel="noreferrer"
                        target="_blank"
                        style={{ color: 'black', textDecoration: 'none' }}
                        href={`mailto:${params.value}`}>
                        {params.value}
                    </a>
                )
            }
        }

        if (col === 'Name') {
            column = {
                ...column,
                renderCell: (params) => (
                    <a
                        rel="noreferrer"
                        target="_blank"
                        style={{ color: 'black', textDecoration: 'none' }}
                        href={`/details/${CryptoJS.HmacSHA256(params.row.Email, ':)').toString()}`}>
                        {params.value}
                    </a>
                )
            }
        }
        return column
    })
    const d = {
        columns,
        rows: data,
        ...props
    }
    return (
        <Box
            sx={{
                width: 'fit-content(4000px)',
                '& .super-app-theme--header': {
                    backgroundColor: '#212B36',
                    color: 'white',
                    fontWeight: 'bolder',
                    fontSize: '16px'
                },
                '& .super-app-theme--cell': {
                    backgroundColor: '#D9D9D9',
                    color: 'black',
                    borderRight: '1px solid black',
                    fontSize: '16px',
                    borderBottom: '1px solid black !important'
                },
                background: '#D9D9D9',
                border: '1px solid black',
                borderRadius: 1,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0
            }}>
            {data && data.length > 0 && (
                <DataGridPro
                    onCellClick={handleCellClick}
                    autoHeight={true}
                    pageSize={10}
                    pagination
                    disableSelectionOnClick={true}
                    sx={{
                        boxShadow: 2,
                        borderRadius: 5,
                        borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 0,
                        color: 'black !important',
                        '& .MuiDataGrid-cell:hover': {
                            color: 'primary.main'
                        },

                        '& .MuiTablePagination-toolbar': {
                            color: 'black',
                            pt: 2
                        }
                    }}
                    {...d}
                />
            )}
        </Box>
    )
}
