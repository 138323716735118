import _ from 'lodash'
import moment from 'moment'

const COMPANY = 'company'
const PERSON = 'person'
const CONVERSATION = 'conversation'

const isLocalhost = () => {
    return (
        window &&
        window.location &&
        window.location.host &&
        window.location.host.includes('localhost')
    )
}

const formatToUSD = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
})

const getFormattedDate = (date, defaultValue = 'N/A') => {
    if (!date) return defaultValue

    const dateObj = new Date(date)
    const month = dateObj.getUTCMonth() + 1 //months from 1-12
    const day = dateObj.getUTCDate()
    const year = dateObj.getUTCFullYear()

    return month + '/' + day + '/' + year
}

const setColorForDate = (date) => {
    const dateObj = moment(date)
    const diff = moment().diff(dateObj, 'months', true)
    if (diff <= 3) return '#66A266'
    if (diff > 3 && diff <= 6) return 'yellow'
    if (diff > 6) return '#cd0030'
}

const v = (obj, path) => _.get(obj, path, 'N/A')

export {
    isLocalhost,
    formatToUSD,
    v,
    getFormattedDate,
    setColorForDate,
    COMPANY,
    PERSON,
    CONVERSATION
}
