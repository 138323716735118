import { Grid, Typography, List, ListItem, ListItemText, Divider, Chip } from '@mui/material'
import { useSelector } from 'react-redux'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import BoyIcon from '@mui/icons-material/Boy'
import FactoryIcon from '@mui/icons-material/Factory'
import EmailIcon from '@mui/icons-material/Email'
import ApartmentIcon from '@mui/icons-material/Apartment'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'
import Person3Icon from '@mui/icons-material/Person3'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import LanguageIcon from '@mui/icons-material/Language'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'
import * as utils from 'shared/utils'
import { COMPANY, CONVERSATION, PERSON } from 'utils/core'
import useResponsive from 'hooks/useResponsive'

const redirectToItemDetailsPage = (hit, history) => {
    history.push(`/details/${hit.objectID}`)
}

const getFormattedDate = (date) => {
    const dateObj = new Date(date)
    const month = dateObj.getUTCMonth() + 1 //months from 1-12
    const day = dateObj.getUTCDate()
    const year = dateObj.getUTCFullYear()

    return month + '/' + day + '/' + year
}

const CompanyHit = ({ hit, data, history }) => {
    const isMobile = useResponsive('down', 'sm')
    return (
        <CardContent
            sx={{ cursor: 'pointer', p: 0, pt: 1, pb: 0, ml: 0, ':last-child': { pb: 0 } }}>
            <Grid
                container
                gap={1}
                sx={{ p: 3, pt: 1, pb: 1, cursor: 'pointer' }}
                onClick={() => {
                    redirectToItemDetailsPage(hit, history)
                }}>
                <Grid item sx={{ position: 'relative', top: '5px', pl: 1 }}>
                    <Grid container>
                        <Box sx={{ mt: '2px', ml: -1 }}>
                            <ApartmentIcon sx={{ fontSize: 35, color: '#009EDB' }} />
                        </Box>
                        <Typography variant="h6" component="div" sx={{ ml: 2, mt: '5px' }}>
                            {_.truncate(hit.name, { length: 24 })}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Divider sx={{ p: 0.5 }} />
            <Grid container gap={1} sx={{ pt: 1, ml: 3 }}>
                <Grid onClick={() => window.open(`https://www.${hit.companyRoot}`)} item>
                    <Button size="medium" sx={{ p: 0, pr: 1, color: 'white' }}>
                        <LanguageIcon sx={{ pr: 0, fontSize: 25 }} />
                        <Chip
                            sx={{
                                background: '#009EDB',
                                cursor: 'pointer',
                                color: 'white',
                                textDecoration: 'underline',
                                fontSize: '14px',
                                ml: 1.5,
                                p: 0.5
                            }}
                            label={_.truncate(`https://www.${hit.companyRoot}`, {
                                length: isMobile ? 28 : 100
                            })}
                        />
                    </Button>
                </Grid>
            </Grid>
            <Divider sx={{ p: 0.5 }} />
            <Box sx={{ ml: 1 }}>
                <nav aria-label="main mailbox folders">
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemIcon>
                                    <Person3Icon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={`Closest Internal Contact`}
                                    secondary={`${
                                        _.get(hit, 'emailMeta.closest_internal_contact') ||
                                        'Information not available'
                                    }`}
                                />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </nav>
            </Box>
        </CardContent>
    )
}

const PersonHit = ({ hit, data, history }) => {
    return (
        <CardContent
            sx={{ cursor: 'pointer', p: 0, pt: 1, pb: 0, ':last-child': { pb: 0 } }}
            onClick={() => {
                redirectToItemDetailsPage(hit, history)
            }}>
            <Grid container gap={1} sx={{ p: 3, pt: 1, pb: 1 }}>
                <Grid item sx={{ position: 'relative', top: '5px' }}>
                    <Grid container>
                        <Box sx={{ mt: '5px' }}>
                            <Person3Icon sx={{ fontSize: 35 }} />
                        </Box>
                        <Grid item sx={{ position: 'relative', top: '10px', left: 10 }}>
                            <Typography
                                component="div"
                                sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                                {hit.person.name}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sx={{ position: 'relative', top: '10px', left: 5, pr: 3 }}>
                    <Typography component="div" sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                        <Chip
                            label={_.get(hit, 'person.socials.email') || 'N/A'}
                            sx={{ ml: 2, backgroundColor: '#64A464' }}
                        />
                    </Typography>
                </Grid>
            </Grid>
            <Divider sx={{ p: 1 }} />
            <Box>
                <nav aria-label="main mailbox folders">
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemIcon>
                                    <FactoryIcon />
                                </ListItemIcon>
                                <ListItemText
                                    onClick={() =>
                                        history.push(`/details/${_.get(hit, 'company')}`)
                                    }
                                    primary={`Company`}
                                    sx={{ color: 'white' }}
                                    secondary={`${_.get(hit, 'person.companyName') || 'N/A'}`}
                                />
                            </ListItemButton>
                        </ListItem>
                        {_.get(hit, 'person.socials.linkedin') && (
                            <ListItem disablePadding>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <LinkedInIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        onClick={() =>
                                            history.push(_.get(hit, 'person.socials.linkedin'))
                                        }
                                        primary={`Linkedin`}
                                        secondary={`${
                                            _.get(hit, 'person.socials.linkedin') || 'N/A'
                                        }`}
                                    />
                                </ListItemButton>
                            </ListItem>
                        )}
                        {_.get(hit, 'person.title') && (
                            <ListItem disablePadding>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <BoyIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={`Title`}
                                        secondary={`${_.get(hit, 'person.title') || 'N/A'}`}
                                    />
                                </ListItemButton>
                            </ListItem>
                        )}
                        {_.get(hit, 'emailMeta.closest_internal_contact') && (
                            <ListItem disablePadding>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <EmailIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={`Inbound: ${_.get(
                                            hit,
                                            'emailMeta.inboundEmails',
                                            'N/A'
                                        )} / Outbound: ${_.get(
                                            hit,
                                            'emailMeta.outboundEmails',
                                            'N/A'
                                        )}`}
                                    />
                                </ListItemButton>
                            </ListItem>
                        )}
                    </List>
                </nav>
            </Box>
        </CardContent>
    )
}

const ConversationHit = ({ hit, data, history }) => {
    let hitName = _.get(hit, 'name', '.').split('.')[0]
    return (
        <CardContent
            sx={{ alignItems: 'center', m: 0, cursor: 'pointer', p: 0, pl: 3 }}
            onClick={() => {
                console.log('clicked person card')
                redirectToItemDetailsPage(hit, history)
            }}>
            <Grid container direction="column" gap={3} sx={{ alignItems: 'left' }} columns={1}>
                <Grid item></Grid>
                <Grid
                    item
                    style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: 'fit-content',
                        wordBreak: 'break-all'
                    }}>
                    <Grid container>
                        <Box>
                            <QuestionAnswerIcon sx={{ fontSize: 30, color: '#e1cd1b' }} />
                        </Box>
                        <Typography variant="h6" component="div" sx={{ ml: 2 }}>
                            {_.truncate(hit.identifier, { length: 35 })}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Divider sx={{ p: 1 }} />
            <Box>
                <Typography component="div" sx={{ mt: 2, ml: 0, fontSize: '16px' }}>
                    <span style={{ fontWeight: 'bolder' }}>Subject:</span>{' '}
                    {_.truncate(hitName, { length: 35 })}
                </Typography>
                <Typography component="div" sx={{ mt: 2, ml: 0, fontSize: '16px' }}>
                    <span style={{ fontWeight: 'bolder' }}>Sent:</span>{' '}
                    {getFormattedDate(hit.emailSentDate)}
                </Typography>
                <Typography component="div" sx={{ mt: 2, ml: 0, fontSize: '16px' }}>
                    <span style={{ fontWeight: 'bolder' }}>Owner:</span> {hit.senderEmail}
                </Typography>
            </Box>
        </CardContent>
    )
}

const Hit = ({ hits, hit, setPreviewRecord }) => {
    const history = useHistory()
    const data = utils.extractCardDataByRecordType(hit)
    const userRole = useSelector((state) => state.app.userRole)

    if (!data || !hit || (userRole === 'OPERATIONS' && data.type.toLowerCase() === 'conversation'))
        return null

    const calculateHitCardBg = (type) => {
        let color1 = '#142a2e'
        let color2 = '#142a2e'
        if (type === CONVERSATION) {
            //color1 = '#142a2e'
            //color2 = '#0e1f22'
            color1 = '#2c3e50'
            color2 = '#202e3c'
        }
        if (type === PERSON) {
            //color1 = '#2c4b50'
            //color2 = '#233b3f'
            color1 = '#2c3e50'
            color2 = '#202e3c'
        }
        if (type === COMPANY) {
            color1 = '#2c3e50'
            color2 = '#202e3c'
        }

        return `linear-gradient(to right, ${color1}, ${color2})`
    }

    return (
        <Card
            sx={{
                minWidth: 330,
                justifyContent: 'center',
                border: '1px solid grey',
                background: calculateHitCardBg(data.type.toLowerCase()),
                m: 1,
                transition: 'all .2s ease-in-out',
                ':hover': {
                    border: '1px solid white'
                }
            }}>
            {data.type.toLowerCase() === 'company' && (
                <CompanyHit hit={hit} data={data} history={history} />
            )}
            {data.type.toLowerCase() === 'person' && (
                <PersonHit hit={hit} data={data} history={history} />
            )}
            {data.type.toLowerCase() === 'conversation' && (
                <ConversationHit hit={hit} data={data} history={history} />
            )}
        </Card>
    )
}

export default Hit
