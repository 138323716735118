import { useEffect } from 'react'
import { connectHits } from 'react-instantsearch-dom'
import TagsHits from './TagsHits'
import HitCard from '../components/hit-card/HitCard'
import { useSelector, useDispatch } from 'react-redux'
import { updateCustomHits } from '../redux/appSlice'

const Hits = ({ hits, setPreviewRecord }) => {
    const dispatch = useDispatch()
    const isTagsView = useSelector((state) => state.app.isTagsView)
    const userTag = useSelector((state) => state.app.userTag)
    const customHits = useSelector((state) => state.app.customHits)
    const shouldRenderSearchResults = useSelector((state) => state.app.shouldRenderSearchResults)

    useEffect(() => {
        if (shouldRenderSearchResults) {
            dispatch(updateCustomHits(hits))
        }
    }, [userTag, hits, shouldRenderSearchResults, dispatch])

    useEffect(() => {
        if (shouldRenderSearchResults) {
            dispatch(updateCustomHits(hits))
        }
    }, [shouldRenderSearchResults, dispatch, hits])

    if (isTagsView) return <TagsHits userTag={userTag} setPreviewRecord={setPreviewRecord} />

    if (!customHits || customHits.length === 0) return null

    return (
        <div className="ais-Hits-list">
            {customHits.map((hit) => (
                <HitCard
                    key={hit.objectID}
                    hit={hit}
                    setPreviewRecord={setPreviewRecord}
                    hits={customHits}
                />
            ))}
        </div>
    )
}

const CustomHits = connectHits(Hits)

export default CustomHits
