import { forwardRef, useMemo } from 'react'
// @mui
import { Box } from '@mui/material'

import NavBar from './nav-bar/NavBar.component'

const Page = forwardRef(({ children, title = '', meta, ...other }, ref) => {
    const memoizedNavBar = useMemo(() => <NavBar />, [])
    return (
        <>
            {memoizedNavBar}
            <Box
                ref={ref}
                {...other}
                sx={{
                    minHeight: '100%',
                    pt: 8
                }}
            >
                {children}
            </Box>
        </>
    )
})

export default Page
