import { useState } from 'react'
import qs from 'qs'
import { InstantSearch, Configure } from 'react-instantsearch/dom'
import { getClientWithHooks } from 'shared/utils'
import CustomHits from 'hits/CustomHits'
import Page from 'components/Page'
import { Pagination } from 'react-instantsearch/dom'
import useSettings from 'hooks/useSettings'
import { searchClient, getIndexName } from 'shared/algoliaHelpers'
import { useHistory, useLocation } from 'react-router-dom'
import { Typography } from '@mui/material'
import { setHasInput, setShouldRenderSearchResults } from 'redux/appSlice'
import { SearchBox } from 'react-instantsearch/dom'
import Refinement from 'components/Refinement'
import { Container } from '@mui/material'
import useResponsive from 'hooks/useResponsive'
import { useSelector, useDispatch } from 'react-redux'

const algoliaIndex = getIndexName()

const DEBOUNCE_TIME = 5

const createURL = (state) => `?${qs.stringify(state)}`

const searchStateToUrl = (pathname, searchState) =>
    searchState ? `${pathname}${createURL(searchState)}` : ''

const urlToSearchState = (location) => qs.parse(location.search.slice(1))

function HomePage() {
    const dispatch = useDispatch()
    const location = useLocation()
    const history = useHistory()
    const isMobile = useResponsive('down', 'sm')
    const clientWithHooks = getClientWithHooks({ setHasInput }, searchClient)
    const [searchState, setSearchState] = useState(urlToSearchState(location))
    const [lastLocation, setLastLocation] = useState(location)
    const customHits = useSelector((state) => state.app.customHits)

    const { themeStretch } = useSettings()

    if (location !== lastLocation) {
        //setSearchState(urlToSearchState(location))
        setLastLocation(location)
    }

    const onSearchStateChange = (searchState) => {
        let debouncedSetState
        clearTimeout(debouncedSetState)
        setSearchState(searchState)
        setShouldRenderSearchResults(false)
        setTimeout(() => {
            history.push(searchStateToUrl(location.pathname, searchState), searchState)

            if (searchState && searchState.query) {
                dispatch(setShouldRenderSearchResults(true))
            }
        }, DEBOUNCE_TIME)
    }

    return (
        <Page title="Home">
            <Container maxWidth={themeStretch ? false : 'xl'} sx={{ pt: 3, pb: 3 }}>
                <Typography
                    component="div"
                    onClick={() => window.open(window.location.origin, '_self')}
                    sx={{
                        display: 'grid',
                        cursor: 'pointer',
                        alignSelf: 'center',
                        textAlign: 'center',
                        fontSize:
                            searchState && searchState.query !== ''
                                ? isMobile
                                    ? '48px'
                                    : '64px'
                                : isMobile
                                ? '64px'
                                : '84px',
                        fontWeight: 'middle',
                        fontFamily: 'KazimirText-Regular, KazimirText-Regular',
                        transitionProperty: 'font-size',
                        transitionDuration: '0.5s',
                        transitionDelay: '0'
                    }}
                >
                    Greybase
                </Typography>
                <InstantSearch
                    searchClient={clientWithHooks}
                    indexName={algoliaIndex}
                    searchState={searchState}
                    onSearchStateChange={onSearchStateChange}
                >
                    <Configure hitsPerPage={10} />
                    <br />
                    <SearchBox
                        translations={{
                            placeholder: isMobile
                                ? 'Search in Greybase'
                                : 'Search in Greybase for a Person or Business'
                        }}
                    />
                    <Refinement />
                    {searchState && searchState.query !== '' && (
                        <div className="ais-Hits">
                            <CustomHits />
                            {customHits && customHits.length !== 0 && (
                                <div id="pagination" className="pagination">
                                    <Pagination showLast />
                                </div>
                            )}
                        </div>
                    )}
                </InstantSearch>
            </Container>
        </Page>
    )
}

export default HomePage
