import Page from 'components/Page'
import { Container } from '@mui/material'

function AdminPage() {
    return (
        <Page title="Admin">
            <Container maxWidth={'100%'}>Admin Section</Container>
        </Page>
    )
}

export default AdminPage
