import { Grid, Button, SvgIcon } from '@mui/material'
import Box from '@mui/material/Box'
import WebIcon from '@mui/icons-material/Web'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import TwitterIcon from '@mui/icons-material/Twitter'
import FacebookIcon from '@mui/icons-material/Facebook'
import ApartmentIcon from '@mui/icons-material/Apartment'
import ReactTooltip from 'react-tooltip'
import useResponsive from 'hooks/useResponsive'
import { ReactComponent as HarmonicSvg } from 'icons/harmonic.svg'
import { ReactComponent as PitchbookSvg } from 'icons/pitchbook.svg'
import { ReactComponent as DriveSvg } from 'icons/drive.svg'

export function Subheader({ hit, details }) {
    const isMobile = useResponsive('down', 'sm')
    return (
        <>
            <ReactTooltip />
            <Box
                sx={{
                    backgroundColor: 'primary.dark',
                    width: '100%',
                    borderBottomLeftRadius: !isMobile ? 15 : 0,
                    borderBottomRightRadius: !isMobile ? 15 : 0
                }}>
                <Grid
                    container
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: `${
                            !isMobile ? 'fit-content(400px) fit-content(200px) 1fr' : '1fr'
                        }`,
                        gap: !isMobile ? '20px' : '5px'
                    }}>
                    <Grid
                        item
                        sx={{
                            alignSelf: 'center',
                            textAlign: isMobile ? 'center' : 'none',
                            fontSize: 32,
                            pl: !isMobile ? 3 : 0
                        }}>
                        <Box>
                            {hit.type === 'company' && (
                                <>
                                    {' '}
                                    <WebIcon sx={{ mb: '-2px' }} />
                                    <Button
                                        onClick={() =>
                                            hit.companyRoot
                                                ? window.open(`https://www.${hit.companyRoot}`)
                                                : null
                                        }
                                        sx={{ fontSize: 16, color: 'white' }}>
                                        {hit.companyRoot || 'N/A'}
                                    </Button>
                                </>
                            )}
                            {hit.type === 'person' && (
                                <>
                                    {' '}
                                    <ApartmentIcon sx={{ mb: '-2px' }} />
                                    <Button
                                        sx={{ fontSize: 16, color: 'white' }}
                                        onClick={() =>
                                            hit.company
                                                ? window.open(`/details/${hit.company}`, '_self')
                                                : ''
                                        }>
                                        {hit.person.companyName || 'N/A'}
                                    </Button>
                                </>
                            )}
                            {hit.type === 'conversation' && (
                                <Box
                                    sx={{
                                        textAlign: 'center',
                                        p: 1,
                                        pb: 1.5,
                                        m: 1
                                    }}>
                                    {' '}
                                    <Button
                                        variant="contained"
                                        sx={{
                                            fontSize: 18,
                                            color: 'white',
                                            backgroundColor: '#161c2468',
                                            ':hover': {
                                                backgroundColor: '#161C24'
                                            }
                                        }}
                                        onClick={() =>
                                            hit.folderData[0]?.webViewLink
                                                ? window.open(hit.folderData[0]?.webViewLink)
                                                : ''
                                        }>
                                        <SvgIcon
                                            sx={{
                                                ml: 0,
                                                mr: 2,
                                                fontSize: '30px',
                                                cursor: 'pointer',
                                                ':hover': {
                                                    border: '1px solid #green'
                                                }
                                            }}
                                            component={DriveSvg}
                                            inheritViewBox
                                        />
                                        /{hit.folderData[0]?.name}
                                    </Button>
                                </Box>
                            )}
                        </Box>
                    </Grid>
                    <Grid
                        item
                        sx={{
                            alignSelf: 'center',
                            fontSize: 32
                        }}></Grid>
                    {!isMobile && (
                        <Grid
                            item
                            sx={{
                                textAlign: 'right',
                                alignSelf: 'center',
                                position: 'relative'
                            }}>
                            {hit.type === 'company' && (
                                <SvgIcon
                                    sx={{
                                        ml: 1,
                                        mb: '-5px',
                                        fontSize: '38px',
                                        cursor: 'pointer',
                                        ':hover': {
                                            border: '1px solid #606A83'
                                        }
                                    }}
                                    onClick={() => window.open(details?.socials.PITCHBOOK.url)}
                                    titleAccess="Pitchbook"
                                    component={PitchbookSvg}
                                    data-tip="Pitchbook"
                                    inheritViewBox
                                />
                            )}
                            {hit.type === 'company' && (
                                <SvgIcon
                                    sx={{
                                        ml: 1,
                                        mb: '-5px',
                                        fontSize: '38px',
                                        cursor: 'pointer',
                                        ':hover': {
                                            border: '1px solid #606A83'
                                        }
                                    }}
                                    onClick={() =>
                                        window.open(
                                            `https://console.harmonic.ai/dashboard/company/${details?.id}`
                                        )
                                    }
                                    titleAccess="Harmonic"
                                    data-tip="Harmonic.ai"
                                    component={HarmonicSvg}
                                    inheritViewBox
                                />
                            )}
                            {hit.type === 'company' && (
                                <FacebookIcon
                                    sx={{
                                        ml: 1,
                                        mb: '-5px',
                                        fontSize: '40px',
                                        cursor: 'pointer',
                                        ':hover': {
                                            border: '1px solid #606A83'
                                        }
                                    }}
                                    data-tip="Facebook"
                                    onClick={() => window.open(details?.socials.FACEBOOK.url)}
                                />
                            )}
                            {hit.type === 'company' && (
                                <TwitterIcon
                                    sx={{
                                        ml: 1,
                                        mb: '-5px',
                                        fontSize: '40px',
                                        cursor: 'pointer',
                                        ':hover': {
                                            border: '1px solid #606A83'
                                        }
                                    }}
                                    data-tip="Twitter"
                                    onClick={() => window.open(details?.socials.TWITTER.url)}
                                />
                            )}
                            {hit.type === 'company' && (
                                <LinkedInIcon
                                    sx={{
                                        ml: 1,
                                        mb: '-5px',
                                        mr: 3,
                                        fontSize: '40px',
                                        cursor: 'pointer',
                                        ':hover': {
                                            border: '1px solid #606A83'
                                        }
                                    }}
                                    data-tip="Linkedin"
                                    onClick={() => window.open(details?.socials.LINKEDIN.url)}
                                />
                            )}
                            {hit.type === 'person' && hit?.person?.socials?.linkedIn && (
                                <LinkedInIcon
                                    sx={{
                                        ml: 1,
                                        mb: '-5px',
                                        mr: 3,
                                        fontSize: '40px',
                                        cursor: 'pointer',
                                        ':hover': {
                                            border: '1px solid #606A83'
                                        }
                                    }}
                                    data-tip="Linkedin"
                                    onClick={() => window.open(hit?.person?.socials?.linkedIn)}
                                />
                            )}
                        </Grid>
                    )}
                </Grid>
            </Box>
            {isMobile && hit.type !== 'conversation' && (
                <Box
                    sx={{
                        backgroundColor: 'primary.dark',
                        width: '100%',
                        mt: '0.5px',
                        p: 1,
                        borderBottomLeftRadius: 15,
                        borderBottomRightRadius: 15
                    }}>
                    <Grid
                        container
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: `1fr`
                        }}>
                        <Grid
                            item
                            sx={{
                                textAlign: 'center',
                                alignSelf: 'center',
                                position: 'relative'
                            }}>
                            {hit.type === 'company' && (
                                <SvgIcon
                                    sx={{
                                        ml: 1,
                                        mb: '-5px',
                                        fontSize: '38px',
                                        cursor: 'pointer',
                                        ':hover': {
                                            border: '1px solid #606A83'
                                        }
                                    }}
                                    onClick={() =>
                                        window.open(
                                            `https://console.harmonic.ai/dashboard/company/${details?.id}`
                                        )
                                    }
                                    component={HarmonicSvg}
                                    inheritViewBox
                                />
                            )}
                            {hit.type === 'company' && (
                                <SvgIcon
                                    sx={{
                                        ml: 1,
                                        mb: '-5px',
                                        fontSize: '38px',
                                        cursor: 'pointer',
                                        ':hover': {
                                            border: '1px solid #606A83'
                                        }
                                    }}
                                    onClick={() => window.open(details?.socials.PITCHBOOK.url)}
                                    component={PitchbookSvg}
                                    inheritViewBox
                                />
                            )}
                            {hit.type === 'company' && (
                                <FacebookIcon
                                    sx={{
                                        ml: 1,
                                        mb: '-5px',
                                        fontSize: '40px',
                                        ':hover': {
                                            border: '1px solid #606A83'
                                        }
                                    }}
                                    onClick={() => window.open(details?.socials.FACEBOOK.url)}
                                />
                            )}
                            {hit.type === 'company' && (
                                <TwitterIcon
                                    sx={{
                                        ml: 1,
                                        mb: '-5px',
                                        fontSize: '40px',
                                        ':hover': {
                                            border: '1px solid #606A83'
                                        }
                                    }}
                                    onClick={() => window.open(details?.socials?.TWITTER.url)}
                                />
                            )}
                            {hit.type === 'company' && (
                                <LinkedInIcon
                                    sx={{
                                        ml: 1,
                                        mb: '-5px',
                                        fontSize: '40px',
                                        ':hover': {
                                            border: '1px solid #606A83'
                                        }
                                    }}
                                    onClick={() => window.open(details?.socials?.linkedIn.url)}
                                />
                            )}
                            {hit.type === 'person' && hit?.person?.socials?.linkedIn && (
                                <LinkedInIcon
                                    sx={{
                                        ml: 1,
                                        mb: '-5px',
                                        fontSize: '40px',
                                        ':hover': {
                                            border: '1px solid #606A83'
                                        }
                                    }}
                                    onClick={() => window.open(hit?.person?.socials?.linkedIn)}
                                />
                            )}
                            {hit.type === 'person' && hit?.person?.socials?.twitter && (
                                <TwitterIcon
                                    sx={{
                                        ml: 1,
                                        mb: '-5px',
                                        fontSize: '40px',
                                        ':hover': {
                                            border: '1px solid #606A83'
                                        }
                                    }}
                                    onClick={() => window.open(hit?.person?.socials?.twitter)}
                                />
                            )}
                        </Grid>
                    </Grid>
                </Box>
            )}
        </>
    )
}
