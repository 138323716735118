import _ from 'lodash'
import { Grid, Button, Chip, Box } from '@mui/material'
import { Image } from 'mui-image'
import {
    Person as PersonIcon,
    Email as EmailIcon,
    Chat as ChatIcon,
    LocationOn as LocationOnIcon
} from '@mui/icons-material'
import useResponsive from 'hooks/useResponsive'
import { getFormattedDate, v, PERSON, CONVERSATION, COMPANY } from 'utils/core'

const getCompanyLocationString = (location) => {
    let state = ''
    let city = ''
    if (location && location.state) {
        state += location.state ? location.state : ''
    }
    if (location && location.city) {
        city += location.city ? location.city : ''
    }
    if (!state && !city) return 'N/A'
    if (!state && city) return city
    if (state && city) return `${city}, ${state}`
}

export function Header({ hit, details }) {
    const isMobile = useResponsive('down', 'sm')
    const isPerson = hit.type === PERSON
    const isConversation = hit.type === CONVERSATION
    const isCompany = hit.type === COMPANY

    return (
        <Grid
            container
            sx={{
                background: 'linear-gradient(to right, #606A83, #3d475e)',
                width: '100%',
                maxHeight: '100px',
                minHeight: '100px',
                borderTopLeftRadius: 15,
                borderTopRightRadius: 15
            }}
            gridTemplateColumns="100px 1fr 1fr">
            <Grid item>
                {isCompany && (
                    <Image
                        alt={hit.name}
                        onClick={() => window.open(`https://www.${hit.companyRoot}`)}
                        src={details.logo_url}
                        width="100px"
                        duration={0}
                        style={{
                            padding: '8px',
                            margin: '0px',
                            cursor: 'pointer'
                        }}
                    />
                )}
                {isPerson && (
                    <Box>
                        <PersonIcon sx={{ fontSize: '80px', pl: 1, pt: 1 }} />
                    </Box>
                )}
                {isConversation && (
                    <Box sx={{ m: isMobile ? '18px' : 'none' }}>
                        <ChatIcon
                            sx={{
                                fontSize: isMobile ? '64px' : '80px',
                                pl: 1,
                                pt: 1
                            }}
                        />
                    </Box>
                )}
            </Grid>
            <Grid
                item
                sx={{
                    alignSelf: 'center',
                    fontSize: 32,
                    paddingLeft: !isMobile ? 2 : 0,
                    paddingRight: !isMobile ? 6 : 0
                }}>
                <Box>
                    <Button
                        onClick={() =>
                            isCompany ? window.open(`https://www.${hit.companyRoot}`) : ''
                        }
                        sx={{ fontSize: isMobile ? 24 : 32, color: 'white' }}>
                        {isCompany
                            ? hit.name
                            : isPerson
                            ? _.truncate(hit.person.name, { length: 24 })
                            : CONVERSATION}
                    </Button>
                </Box>
            </Grid>
            {!isMobile && (
                <Grid
                    item
                    sx={{
                        alignSelf: 'center',
                        textAlign: 'left',
                        fontSize: 32
                    }}>
                    <Box>
                        {isCompany && (
                            <>
                                {' '}
                                <LocationOnIcon sx={{ mb: '-3px' }} />
                                <Button sx={{ fontSize: 16, color: 'white' }}>
                                    {getCompanyLocationString(details.location)}
                                </Button>
                            </>
                        )}
                        {isPerson && (
                            <>
                                {' '}
                                <EmailIcon sx={{ mb: '-3px' }} />
                                <Button sx={{ fontSize: 16, color: 'white' }}>
                                    {_.get(hit, 'person.socials.email')}
                                </Button>
                            </>
                        )}
                        {isConversation && (
                            <Grid
                                container
                                sx={{
                                    display: 'grid',
                                    lineHeight: '20px'
                                }}>
                                <Grid item>
                                    <Button sx={{ fontSize: 16, color: 'white' }}>
                                        <Chip
                                            sx={{
                                                background: '#66A266',
                                                cursor: 'pointer',
                                                color: 'white',
                                                fontSize: '14px',
                                                ml: 1.5,
                                                p: 0.5,
                                                mr: 1
                                            }}
                                            label="from"
                                        />
                                        <a
                                            style={{ color: 'white' }}
                                            href={`mailto:${hit.senderEmail}`}>
                                            {hit.senderEmail}
                                        </a>
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        sx={{ fontSize: 16, color: 'white', cursor: 'default' }}>
                                        <Chip
                                            sx={{
                                                background: '#66A266',
                                                color: 'white',
                                                fontSize: '14px',
                                                ml: 1.5,
                                                p: 0.5,
                                                mr: 1.2
                                            }}
                                            label="sent"
                                        />
                                        {getFormattedDate(hit.emailSentDate)}
                                    </Button>
                                </Grid>
                            </Grid>
                        )}
                    </Box>
                </Grid>
            )}
        </Grid>
    )
}
