import { useEffect } from 'react'
import _ from 'lodash'
import Page from '../components/Page'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Container } from '@mui/material'
import { Grid, Button } from '@mui/material'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import DeleteIcon from '@mui/icons-material/Delete'
import { fetchTagsByUserTag, removeTag } from '../redux/appSlice'
import ApartmentIcon from '@mui/icons-material/Apartment'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'
import Person3Icon from '@mui/icons-material/Person3'
import ClipLoader from 'react-spinners/ClipLoader'
import useResponsive from '../hooks/useResponsive'
import ItemDetailsPage from '../pages/ItemDetails'

function insertParam(key, value) {
    key = encodeURIComponent(key)
    value = encodeURIComponent(value)

    // kvp looks like ['key1=value1', 'key2=value2', ...]
    var kvp = document.location.search.substr(1).split('&')
    let i = 0

    for (; i < kvp.length; i++) {
        if (kvp[i].startsWith(key + '=')) {
            let pair = kvp[i].split('=')
            pair[1] = value
            kvp[i] = pair.join('=')
            break
        }
    }

    if (i >= kvp.length) {
        kvp[kvp.length] = [key, value].join('=')
    }

    // can return this or...
    let params = kvp.join('&')

    // reload page with new params
    document.location.search = params
}

const getTagName = (tag) =>
    _.get(
        tag,
        tag && tag.details && tag.details.type === 'conversation'
            ? 'details.identifier'
            : 'details.name',
        '.'
    ).split('.')[0]

function TagsPage() {
    const dispatch = useDispatch()
    const isMobile = useResponsive('down', 'sm')

    const userTag = useSelector((state) => state.app.userTag)
    const tagsByUserTag = useSelector((state) => state.app.tagsByUserTag)
    const tagsByUserTagStatus = useSelector((state) => state.app.tagsByUserTagStatus)
    const history = useHistory()

    const BasicCard = ({ type = 'conversation', id = '', tag, tags, onRemove }) => {
        return (
            <Card
                sx={{
                    borderRadius: 2,
                    m: 0.5,
                    p: 0.5,
                    mb: 2,
                    minWidth: '300px',
                    maxWidth: '500px',
                    textAlign: 'center',
                    border: '1px solid grey',
                    transition: 'all .2s ease-in-out',
                    ':hover': {
                        transform: 'scale(1.01)',
                        border: '1px solid grey'
                    }
                }}>
                <CardContent
                    onClick={() => {
                        if (isMobile) {
                            history.push(`/details/${tag.tag.id}`)
                        } else {
                            insertParam('selectedId', tag.tag.id)
                        }
                    }}
                    sx={{ p: 0.5, cursor: 'pointer' }}>
                    <Box display="flex" justifyContent="center" alignItems="center" sx={{ pb: 0 }}>
                        {type === 'person' && <Person3Icon sx={{ color: 'white' }} />}
                        {type === 'company' && <ApartmentIcon sx={{ color: '#009EDB' }} />}
                        {type === 'conversation' && (
                            <QuestionAnswerIcon sx={{ color: '#e1cd1b' }} />
                        )}
                        <Typography
                            noWrap
                            sx={{
                                fontWeight: 'bolder',
                                textAlign: 'center',
                                p: 0,
                                pt: 0,
                                pl: 1,
                                mt: '10px',
                                fontSize: '14'
                            }}
                            color="text.secondary"
                            gutterBottom>
                            {getTagName(tag)}
                        </Typography>
                    </Box>
                </CardContent>
                <CardActions style={{ justifyContent: 'center', p: '0 !important' }}>
                    <Button
                        size="small"
                        color="error"
                        variant="contained"
                        onClick={() => onRemove(id, tag)}>
                        <DeleteIcon />
                    </Button>
                </CardActions>
            </Card>
        )
    }

    const handleRemoveTag = (id, removedTag) => {
        dispatch(
            removeTag({
                id,
                removedTag: removedTag.tag,
                userTag
            })
        )
    }

    useEffect(() => {
        if (!userTag) return
        dispatch(fetchTagsByUserTag(userTag))
    }, [dispatch, userTag])

    const renderTagsByPriority = (tagsByUserTag, priority) => {
        if (!tagsByUserTag || !tagsByUserTag[priority]) return null
        return tagsByUserTag[priority].map((tag, index) => (
            <BasicCard
                key={`tags-p1-${index}-elem`}
                id={tag.tag.id}
                type={tag.tag.type}
                tag={tag}
                tags={tagsByUserTag[priority]}
                onRemove={handleRemoveTag}
            />
        ))
    }

    const PriorityButtonLabel = ({ label }) => {
        return (
            <Box
                sx={{
                    textAlign: 'center',
                    p: 2,
                    pb: 0,
                    borderBottom: '1px solid rgba(145, 158, 171, 0.24);'
                }}>
                <Button
                    onClick={null}
                    variant="text"
                    color="primary"
                    sx={{
                        color: 'white',
                        fontSize: 20,
                        fontWeight: 600,
                        fontFamily: 'inherit',
                        width: '200px',
                        borderRadius: 2
                    }}>
                    {label}
                </Button>
            </Box>
        )
    }

    if (tagsByUserTagStatus === 'loading') {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <ClipLoader color="#36d7b7" />
            </Box>
        )
    }

    const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop)
    })

    const selectedId = params.selectedId

    return (
        <Page title="Tags">
            <Container
                maxWidth={'100%'}
                sx={{
                    ml: 0,
                    display: 'grid',
                    gridTemplateColumns: `${isMobile ? '1fr' : 'fit-content(500px) 1fr'}`,
                    filter: `${tagsByUserTagStatus === 'loading' ? 'blur(4px)' : ''};`
                }}>
                <Grid
                    container
                    direction="column"
                    sx={{
                        width: '100%',
                        borderRight: `${!isMobile ? '1px solid rgba(145, 158, 171, 0.24);' : ''}`,
                        p: 2,
                        pt: 1,
                        pr: `${!isMobile ? 2 : 4}`,
                        height: '100vh'
                    }}>
                    {[1, 2, 3].map((priority, idx) => {
                        return (
                            <Grid
                                key={`${idx}-${priority}`}
                                item
                                sx={{ mt: priority === 1 && idx === 0 && !isMobile ? 6 : -1 }}>
                                {tagsByUserTag &&
                                tagsByUserTag[`p${priority}`] &&
                                tagsByUserTag[`p${priority}`].length > 0 ? (
                                    <Grid item>
                                        {renderTagsByPriority(tagsByUserTag, `p${priority}`)}
                                    </Grid>
                                ) : (
                                    <div />
                                )}
                            </Grid>
                        )
                    })}
                </Grid>

                <Grid
                    container
                    direction="column"
                    sx={{ m: 2, mt: 0, p: 0, display: { xs: 'none', md: 'flex' } }}>
                    <Grid item>
                        {selectedId ? (
                            <ItemDetailsPage providedId={selectedId} renderRelatedResults={false} />
                        ) : (
                            <Button variant="contained" color="primary" sx={{ mt: 3 }}>
                                Select an item from the left pane
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </Container>
        </Page>
    )
}

export default TagsPage
