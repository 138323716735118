import { useState, useEffect } from 'react'
import moment from 'moment'
import _ from 'lodash'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import ClipLoader from 'react-spinners/ClipLoader'
import { Container, Grid, Box, Button, Typography, Chip } from '@mui/material'
import Page from 'components/Page'
import useResponsive from 'hooks/useResponsive'
import { getByObjectId } from 'shared/algoliaHelpers'
import {
    isLocalhost,
    formatToUSD,
    getFormattedDate,
    setColorForDate,
    COMPANY,
    PERSON,
    CONVERSATION
} from 'utils/core'
import PieChartGraphic from 'components/pie-chart'

import {
    Header,
    InfoContainer,
    Subheader,
    DataGridTable,
    TagsHeader,
    RelatedHitCard
} from 'components/details'

import { getAccessToken } from 'redux/appSlice'

function ItemDetailsPage({ providedId, renderRelatedResults = true }) {
    let { id } = useParams()
    //const xp = id?.split('&')[1]?.split('=')[1]
    const xp = 'test'
    //id = id.split('&')[0]
    if (!id && providedId) {
        id = providedId
    }

    const [tags, setTags] = useState(null)

    const [peopleData, setPeopleData] = useState([])
    const [companyEmailData, setCompanyEmailData] = useState([])
    const [companyMeetingsData, setCompanyMeetingsData] = useState([])
    const [relationshipOwners, setRelationshipOwners] = useState([])
    const [tagHistory, setTagHistory] = useState([])

    const [personEmails, setPersonEmails] = useState([])
    const [personMeetings, setPersonMeetings] = useState([])

    let customHits = useSelector((state) => state.app.customHits)
    if (customHits && customHits.length > 0) {
        localStorage.setItem('customHits', JSON.stringify(customHits))
    } else {
        customHits = JSON.parse(localStorage.getItem('customHits'))
    }
    const [currentlySelectedId, setCurrentlySelectedId] = useState(id)

    const [selectedHit, setSelectedHit] = useState(findSelectedHit(customHits, id))
    const [details, setDetails] = useState()

    const isMobile = useResponsive('down', 'sm')

    const userRole = useSelector((state) => state.app.userRole)

    useEffect(() => {
        async function getPersonEmails(email) {
            const response = await fetch(
                `${isLocalhost() ? 'http://localhost:3001' : ''}/sigParser/emailData/${email}`
            )
            const peopleData = await response.json()
            setPersonEmails(peopleData)
        }

        if (
            selectedHit &&
            selectedHit.type === PERSON &&
            (!personEmails || personEmails.length === 0)
        ) {
            getPersonEmails(selectedHit?.person?.socials?.email)
        }
    }, [id, selectedHit, personEmails])

    useEffect(() => {
        async function getPersonMeetings(email) {
            const response = await fetch(
                `${isLocalhost() ? 'http://localhost:3001' : ''}/sigParser/meetingData/${email}`
            )
            const peopleData = await response.json()
            setPersonMeetings(peopleData)
        }

        if (
            selectedHit &&
            selectedHit.type === PERSON &&
            (!personMeetings || personMeetings.length === 0)
        ) {
            getPersonMeetings(selectedHit?.person?.socials?.email)
        }
    }, [id, selectedHit, personMeetings])

    useEffect(() => {
        async function getRelationshipMetrics(domain) {
            const response = await fetch(
                `${
                    isLocalhost() ? 'http://localhost:3001' : ''
                }/sigParser/company/relations/${domain}`
            )
            const relationshipData = await response.json()
            setRelationshipOwners(relationshipData)
        }

        async function getCompanyTagHistory(id) {
            const response = await fetch(
                `${isLocalhost() ? 'http://localhost:3001' : ''}/api/v1/tag/history/${id}`
            )
            const tagHistoryData = await response.json()
            setTagHistory(tagHistoryData)
        }

        if (
            selectedHit &&
            selectedHit.type === COMPANY &&
            (!relationshipOwners || relationshipOwners.length === 0)
        ) {
            getRelationshipMetrics(selectedHit?.companyRoot)
        }
        if (selectedHit && selectedHit.companyRoot) {
            getCompanyTagHistory(selectedHit?.companyRoot)
        }
        if (selectedHit && selectedHit.type === CONVERSATION) {
            getCompanyTagHistory(selectedHit?.fileId)
        }
    }, [id, selectedHit, relationshipOwners])

    useEffect(() => {
        async function getPeopleData(domain) {
            const response = await fetch(
                `${isLocalhost() ? 'http://localhost:3001' : ''}/sigParser/peopleData/${domain}`
            )
            const peopleData = await response.json()
            setPeopleData(peopleData)
        }

        if (
            selectedHit &&
            selectedHit.type === COMPANY &&
            (!peopleData || peopleData.length === 0)
        ) {
            getPeopleData(selectedHit.companyRoot)
        }
    }, [id, selectedHit, peopleData])

    useEffect(() => {
        async function getCompanyMeetingsData(domain) {
            const response = await fetch(
                `${
                    isLocalhost() ? 'http://localhost:3001' : ''
                }/sigParser/meetingDataByCompany/${domain}`
            )
            const companyMeetingsData = await response.json()
            setCompanyMeetingsData(companyMeetingsData)
        }

        if (selectedHit && selectedHit.type === COMPANY) {
            getCompanyMeetingsData(selectedHit.companyRoot)
        }
    }, [id, selectedHit])

    useEffect(() => {
        async function getEmailData(domain) {
            const response = await fetch(
                `${
                    isLocalhost() ? 'http://localhost:3001' : ''
                }/sigParser/emailDataByCompany/${domain}`
            )
            const peopleData = await response.json()
            setCompanyEmailData(peopleData)
        }

        if (
            selectedHit &&
            selectedHit.type === COMPANY &&
            (!companyEmailData || companyEmailData.length === 0)
        ) {
            getEmailData(selectedHit.companyRoot)
        }
    }, [id, selectedHit, companyEmailData])

    useEffect(() => {
        async function getSelectedHit(id) {
            const selectedHitResponse = await getByObjectId(id)
            const selectedHitItem = selectedHitResponse.results[0]
            setSelectedHit(selectedHitItem)
        }

        async function getdetails(domain) {
            const response = await fetch(
                `${isLocalhost() ? 'http://localhost:3001' : ''}/api/v1/company/${domain}/details`,
                {
                    headers: {
                        accept: 'application/json',
                        apikey: 'egc5oydmG07ZW4bCLBVVWSFilCcnhHIH'
                    }
                }
            )
            const details = await response.json()
            setDetails(details)
        }

        async function getTags(id) {
            let accessToken = getAccessToken()
            try {
                const response = await fetch(
                    `${isLocalhost() ? 'http://localhost:3001' : ''}/api/v1/tag/${id}`,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            authorization: `Bearer ${accessToken}`
                        }
                    }
                )
                const tagsResponse = await response.json()
                setTags(tagsResponse)
            } catch (e) {
                console.log(`Error getting tags. Details: ${JSON.stringify(e)}`)
            }
        }

        if (!selectedHit) {
            getSelectedHit(id)
        } else {
            if (selectedHit.type === COMPANY) {
                getTags(selectedHit.companyRoot)
                getdetails(selectedHit.companyRoot)
            } else {
                getTags(selectedHit.objectID)
            }
        }
    }, [id, selectedHit])

    if (!selectedHit || (selectedHit.type === COMPANY && !details)) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <ClipLoader color="#36d7b7" />
            </Box>
        )
    }

    const renderRelatedHits = (relatedHits, currentlySelectedId) => {
        return relatedHits.map((hit, index) => (
            <RelatedHitCard
                key={`tags-p1-${index}-elem`}
                id={hit.objectID}
                type={hit.type}
                hit={hit}
                currentlySelectedId={currentlySelectedId}
            />
        ))
    }

    const desktopPageStyles = !_.isEmpty(customHits)
        ? {
              display: 'grid',
              width: '100%',
              gridTemplateColumns: renderRelatedResults ? 'fit-content(500px) 1fr' : '1fr'
          }
        : { pt: !providedId ? 3 : 0, pb: 3 }

    const mobilePageStyles = {
        display: 'grid',
        width: '100%',
        gridTemplateColumns: '1fr'
    }

    return (
        <Page title="Item Details">
            <Container maxWidth={'100%'} sx={isMobile ? mobilePageStyles : desktopPageStyles}>
                {!_.isEmpty(customHits) && !isMobile && renderRelatedResults && (
                    <Grid
                        container
                        direction="column"
                        sx={{
                            width: 'fit-content',
                            borderRight: '1px solid rgba(145, 158, 171, 0.24);',
                            p: 2,
                            pt: 0
                        }}>
                        <Grid
                            item
                            sx={{
                                pt: 6
                            }}>
                            {customHits.length > 0 ? (
                                <Grid item sx={{ pt: 1 }}>
                                    {renderRelatedHits(customHits, currentlySelectedId)}
                                </Grid>
                            ) : (
                                <Typography sx={{ p: 2, textAlign: 'center', fontWeight: '800' }}>
                                    No related items
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                )}

                <Grid
                    container
                    direction="column"
                    gap={0.1}
                    sx={{
                        p: isMobile ? 0 : 3,
                        color: '#38475c',
                        pt: isMobile || providedId ? 0 : 8,
                        background:
                            'conic-gradient(from var(--b) at var(--xy), var(--c0) var(--a), var(--c1) 0% 50%, var(--c2) 0% calc(180deg + var(--a)), var(--c3) 0%)'
                    }}
                    className={!isMobile && !providedId ? 'pattern-diagonal-lines-sm' : ''}>
                    <Grid item sx={{ color: 'white' }}>
                        <Header hit={selectedHit} details={details} />
                        {/* <ItemDetailsHeader hit={selectedHit} details={details} /> */}
                    </Grid>
                    {tags && (
                        <Grid sx={{ color: 'white' }}>
                            <TagsHeader tags={tags} tagHistory={[]} />
                        </Grid>
                    )}
                    <Grid item sx={{ color: 'white' }}>
                        <Subheader hit={selectedHit} details={details} />
                    </Grid>
                    <div style={{ height: '25px' }} />
                    {details && selectedHit?.type === COMPANY && (
                        <InfoContainer label="Funding" color="#606A83">
                            <DataGridTable
                                data={
                                    !isMobile
                                        ? [
                                              {
                                                  id: 1,
                                                  'Fund Stage': details?.funding?.funding_stage
                                                      ? details?.funding?.funding_stage?.replaceAll(
                                                            '_',
                                                            ' '
                                                        )
                                                      : 'N/A',
                                                  'Last Fund Stage': details?.funding
                                                      ?.last_funding_at
                                                      ? moment(
                                                            details?.funding?.last_funding_at
                                                        ).format('MM/DD/YYYY')
                                                      : 'N/A',
                                                  'Last Fund': formatToUSD.format(
                                                      details?.funding?.funding_total
                                                  ),
                                                  Total: formatToUSD.format(
                                                      details?.funding?.funding_total
                                                  )
                                              }
                                          ]
                                        : [
                                              {
                                                  Item: 'Fund Stage',
                                                  Value: details?.funding?.last_funding_at
                                                      ? moment(
                                                            details?.funding?.last_funding_at
                                                        ).format('MM-DD-YYYY')
                                                      : 'N/A',
                                                  id: 0
                                              },
                                              {
                                                  Item: 'Last Fund Stage',
                                                  Value: details?.funding?.last_funding_at
                                                      ? moment(
                                                            details?.funding?.last_funding_at
                                                        ).format('MM-DD-YYYY')
                                                      : 'N/A',
                                                  id: 1
                                              },
                                              {
                                                  Item: 'Last Fund',
                                                  Value: formatToUSD.format(
                                                      details?.funding?.funding_total
                                                  ),
                                                  id: 2
                                              },
                                              {
                                                  Item: 'Total',
                                                  Value: formatToUSD.format(
                                                      details?.funding?.funding_total
                                                  ),
                                                  id: 3
                                              }
                                          ]
                                }
                                sortable="no"
                                props={{ hideFooter: true, disableColumnMenu: true }}
                            />
                        </InfoContainer>
                    )}
                    {selectedHit?.type === COMPANY && (
                        <InfoContainer label="Engagement">
                            <Grid
                                container
                                sx={{
                                    display: 'grid',
                                    gridTemplateColumns: isMobile ? 'none' : '1fr 1fr',
                                    gap: '20px'
                                }}>
                                <Grid item>
                                    <Button
                                        variant="text"
                                        sx={{ color: 'white', fontSize: '20px', mb: 1 }}>
                                        Last Interaction{' '}
                                        <Chip
                                            sx={{
                                                background: setColorForDate(
                                                    selectedHit?.emailMeta?.lastInteraction ||
                                                        selectedHit?.emailMeta?.last_interaction
                                                ),
                                                fontSize: '20px',
                                                ml: 1,
                                                color: 'black'
                                            }}
                                            label={getFormattedDate(
                                                selectedHit?.emailMeta?.lastInteraction ||
                                                    selectedHit?.emailMeta?.last_interaction
                                            )}
                                        />
                                    </Button>
                                    <DataGridTable
                                        data={
                                            !isMobile
                                                ? [
                                                      {
                                                          id: 1,
                                                          'Closest Contact': selectedHit?.emailMeta
                                                              ?.closest_internal_contact
                                                              ? selectedHit?.emailMeta
                                                                    ?.closest_internal_contact
                                                              : 'N/A',
                                                          'First Contact': selectedHit?.emailMeta
                                                              ?.internal_contact_first
                                                              ? selectedHit?.emailMeta
                                                                    ?.internal_contact_first
                                                              : 'N/A',
                                                          'Latest Contact': selectedHit?.emailMeta
                                                              ?.internal_contact_latest
                                                              ? selectedHit?.emailMeta
                                                                    ?.internal_contact_latest
                                                              : 'N/A'
                                                      }
                                                  ]
                                                : [
                                                      {
                                                          Item: 'Closest Contact',
                                                          Value: selectedHit?.emailMeta
                                                              ?.closest_internal_contact
                                                              ? selectedHit?.emailMeta
                                                                    ?.closest_internal_contact
                                                              : 'N/A',
                                                          id: 0
                                                      },
                                                      {
                                                          Item: 'First Contact',
                                                          Value: selectedHit?.emailMeta
                                                              ?.internal_contact_first
                                                              ? selectedHit?.emailMeta
                                                                    ?.internal_contact_first
                                                              : 'N/A',
                                                          id: 1
                                                      },
                                                      {
                                                          Item: 'Latest Contact',
                                                          Value: selectedHit?.emailMeta
                                                              ?.internal_contact_latest
                                                              ? selectedHit?.emailMeta
                                                                    ?.internal_contact_latest
                                                              : 'N/A',
                                                          id: 2
                                                      }
                                                  ]
                                        }
                                        sortable="no"
                                        props={{ hideFooter: true, disableColumnMenu: true }}
                                    />
                                </Grid>
                                {relationshipOwners && relationshipOwners.length > 0 && (
                                    <Grid item sx={{ textAlign: 'center' }}>
                                        <Button
                                            sx={{ color: 'white', fontSize: '20px', mt: '-5px' }}>
                                            Relationship
                                            <Button sx={{ color: 'white', fontSize: '20px' }}>
                                                Owners
                                            </Button>
                                        </Button>
                                        {isMobile && (
                                            <DataGridTable
                                                data={relationshipOwners.map((item, idx) => {
                                                    return { ...item, id: idx }
                                                })}
                                                sortable="no"
                                                props={{
                                                    hideFooter: true,
                                                    disableColumnMenu: true
                                                }}
                                            />
                                        )}
                                        {!isMobile && xp === 'graphic' && (
                                            <PieChartGraphic
                                                data={relationshipOwners.map((item) => {
                                                    return {
                                                        name: item['Team Member'],
                                                        value: item.Interactions
                                                    }
                                                })}
                                            />
                                        )}
                                        {!isMobile && xp !== 'graphic' && (
                                            <DataGridTable
                                                data={relationshipOwners.map((item, idx) => {
                                                    return { ...item, id: idx }
                                                })}
                                                sortable="no"
                                                props={{
                                                    hideFooter: true,
                                                    disableColumnMenu: true
                                                }}
                                            />
                                        )}
                                    </Grid>
                                )}
                            </Grid>
                        </InfoContainer>
                    )}
                    {selectedHit?.type === PERSON && (
                        <InfoContainer label="Engagement">
                            <Grid
                                container
                                sx={{
                                    display: 'grid',
                                    gridTemplateColumns: !isMobile ? '1fr 1fr' : '1fr',
                                    gap: '20px'
                                }}>
                                <Grid item>
                                    <Button
                                        variant="text"
                                        sx={{ color: 'white', fontSize: '20px', mb: 1 }}>
                                        Last Interaction:{' '}
                                        <Chip
                                            sx={{
                                                background: setColorForDate(
                                                    selectedHit?.emailMeta?.lastInteraction ||
                                                        selectedHit?.emailMeta?.last_interaction
                                                ),
                                                fontSize: '20px',
                                                ml: 1,
                                                color: 'black'
                                            }}
                                            label={getFormattedDate(
                                                selectedHit?.emailMeta?.lastInteraction ||
                                                    selectedHit?.emailMeta?.last_interaction
                                            )}
                                        />
                                    </Button>
                                    <DataGridTable
                                        data={
                                            !isMobile
                                                ? [
                                                      {
                                                          'Outbound Emails':
                                                              selectedHit?.emailMeta
                                                                  .outboundEmails || 'N/A',
                                                          'Inbound Emails':
                                                              selectedHit?.emailMeta
                                                                  .inboundEmails || 'N/A',
                                                          Meetings:
                                                              selectedHit?.emailMeta.meetings ||
                                                              'N/A',
                                                          id: 0
                                                      }
                                                  ]
                                                : [
                                                      {
                                                          Item: 'Outbound Emails',
                                                          Value:
                                                              selectedHit?.emailMeta.outboundEmails,
                                                          id: 0
                                                      },
                                                      {
                                                          Item: 'Inbound Emails',
                                                          Value:
                                                              selectedHit?.emailMeta.inboundEmails,
                                                          id: 1
                                                      },
                                                      {
                                                          Item: 'Meetings',
                                                          Value:
                                                              selectedHit?.emailMeta.meetings ||
                                                              'N/A',
                                                          id: 2
                                                      }
                                                  ]
                                        }
                                        props={{ hideFooter: true, disableColumnMenu: true }}
                                    />
                                </Grid>
                                <Grid item sx={{ pt: !isMobile ? '46px' : 0, mt: 1 }}>
                                    <DataGridTable
                                        data={
                                            !isMobile
                                                ? [
                                                      {
                                                          'Closest Contact':
                                                              selectedHit?.emailMeta
                                                                  ?.closest_internal_contact,
                                                          'First Contact':
                                                              selectedHit?.emailMeta
                                                                  ?.internal_contact_first,
                                                          'Latest Contact':
                                                              selectedHit?.emailMeta
                                                                  ?.internal_contact_latest,
                                                          id: 0
                                                      }
                                                  ]
                                                : [
                                                      {
                                                          Item: 'Closest Contact',
                                                          Value:
                                                              selectedHit?.emailMeta
                                                                  ?.closest_internal_contact,
                                                          id: 0
                                                      },
                                                      {
                                                          Item: 'First Contact',
                                                          Value:
                                                              selectedHit?.emailMeta
                                                                  ?.internal_contact_first,
                                                          id: 1
                                                      },
                                                      {
                                                          Item: 'Latest Contact',
                                                          Value:
                                                              selectedHit?.emailMeta
                                                                  ?.internal_contact_latest,
                                                          id: 2
                                                      }
                                                  ]
                                        }
                                        props={{ hideFooter: true, disableColumnMenu: true }}
                                    />
                                </Grid>
                            </Grid>
                        </InfoContainer>
                    )}
                    {!isMobile && selectedHit?.type === COMPANY && (
                        <InfoContainer label="People">
                            <DataGridTable data={peopleData} />
                        </InfoContainer>
                    )}
                    {!isMobile &&
                        selectedHit?.type === COMPANY &&
                        companyEmailData &&
                        companyEmailData.length > 0 && (
                            <InfoContainer label="Emails">
                                <DataGridTable
                                    data={companyEmailData?.map((item) => {
                                        if (userRole === 'OPERATIONS') {
                                            delete item.Subject
                                        }
                                        return item
                                    })}
                                />
                            </InfoContainer>
                        )}
                    {!isMobile &&
                        selectedHit?.type === COMPANY &&
                        companyMeetingsData &&
                        companyMeetingsData.length > 0 && (
                            <InfoContainer label="Meetings">
                                <DataGridTable
                                    data={companyMeetingsData?.map((item) => {
                                        if (userRole === 'OPERATIONS') {
                                            delete item.Subject
                                        }
                                        return item
                                    })}
                                />
                            </InfoContainer>
                        )}
                    {!isMobile && selectedHit?.type === PERSON && (
                        <InfoContainer label="Emails">
                            <DataGridTable
                                data={personEmails?.map((item) => {
                                    if (userRole === 'OPERATIONS') {
                                        delete item.Subject
                                    }
                                    return item
                                })}
                            />
                        </InfoContainer>
                    )}
                    {!isMobile &&
                        selectedHit?.type === PERSON &&
                        personMeetings &&
                        personMeetings.length > 0 && (
                            <InfoContainer label="Meetings">
                                <DataGridTable
                                    data={personMeetings?.map((item) => {
                                        if (userRole === 'OPERATIONS') {
                                            delete item.Subject
                                        }
                                        return item
                                    })}
                                />
                            </InfoContainer>
                        )}
                    {selectedHit?.type === CONVERSATION && (
                        <InfoContainer label="Conversation" color="#FFF0E4">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: selectedHit.content
                                }}
                                style={{
                                    color: 'black',
                                    fontFamily: 'Public Sans !important',
                                    fontSize: '16px'
                                }}
                            />
                        </InfoContainer>
                    )}
                    {tagHistory && tagHistory.length > 0 && (
                        <InfoContainer label="Tag History" color="#FFF0E4">
                            {tagHistory
                                .sort(function (a, b) {
                                    return new Date(b.creationdate) - new Date(a.creationdate)
                                })
                                .map((i, idx) => (
                                    <div
                                        style={{
                                            color: 'black',
                                            fontFamily: 'Public Sans !important',
                                            fontSize: '16px'
                                        }}
                                        key={`${i}-${idx}`}>
                                        {moment(i.creationdate).format('MM/DD/YYYY')} | <b>tag</b>:{' '}
                                        {i.value}, <b>user</b>: {i.userid}
                                    </div>
                                ))}
                        </InfoContainer>
                    )}
                </Grid>
            </Container>
        </Page>
    )
}

const findSelectedHit = (customHits, id) => {
    if (!customHits) {
        return {}
    }
    return customHits.filter((hit) => hit.objectID === id)[0]
}

export default ItemDetailsPage
