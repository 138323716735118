import _ from 'lodash'
import { Grid, Button } from '@mui/material'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'

export function TagsHeader({ tags, tagHistory }) {
    if (!tags || _.isEmpty(tags)) return <></>
    return (
        <Grid
            container
            sx={{
                backgroundColor: 'primary.dark',
                width: '100%'
            }}>
            <Grid item sx={{ mt: 1, ml: 1, mr: 0.5 }}>
                <LocalOfferIcon fontSize="large" />
            </Grid>
            <Grid item>
                {tags[0]?.tags?.map((tag, idx) => {
                    return (
                        <Button
                            key={`tag-${tag}-${idx}`}
                            variant="contained"
                            color="warning"
                            size="small"
                            sx={{
                                color: 'black',
                                m: 1,
                                backgroundColor: 'white'
                            }}>
                            {tag}
                        </Button>
                    )
                })}
            </Grid>
            {tagHistory && (
                <Grid item>
                    {tagHistory.map((tag, idx) => {
                        return (
                            <Button
                                key={`tag-${tag.value}-${idx}`}
                                variant="contained"
                                color="warning"
                                size="small"
                                sx={{
                                    color: 'black',
                                    m: 1,
                                    backgroundColor: 'grey',
                                    boxShadow: 'none'
                                }}>
                                {tag.value}
                            </Button>
                        )
                    })}
                </Grid>
            )}
        </Grid>
    )
}
