import { Grid } from '@mui/material'

export function InfoContainer({
    label,
    children,
    color,
    textColor = 'white',
    marginBottom = 3,
    className = ''
}) {
    return (
        <Grid
            container
            sx={{
                display: 'grid',
                background: color ? color : 'linear-gradient(to right, #606A83, #3d475e)',
                marginBottom: marginBottom ? marginBottom : 0,
                color: textColor,
                border: '1px solid white'
            }}
            className={className}
        >
            <Grid
                item
                sx={{
                    height: '35px',
                    backgroundColor: '#212B36',
                    width: '200px',
                    pt: '5px',
                    fontWeight: 'bolder',
                    textAlign: 'center'
                }}
            >
                {label}
            </Grid>
            <Grid item sx={{ p: 2 }}>
                {children}
            </Grid>
        </Grid>
    )
}
