import ForumIcon from '@material-ui/icons/Forum'
import BusinessIcon from '@material-ui/icons/Business'
import PersonIcon from '@material-ui/icons/Person'
import { updateRecords } from 'shared/algoliaHelpers'
import { format, parseJSON, fromUnixTime } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

export const extractLookupIds = (personLookUps) => {
    const objectIDs = []
    personLookUps.forEach((lookup) => {
        if (lookup.type === 'personLookUp') {
            objectIDs.push(...lookup.conversations)
            objectIDs.push(...lookup.relationships)
        }
    })
    return objectIDs
}

export const extractCompanyRoot = (email) => {
    const regEx = /\@(.*)/
    const regExArr = regEx.exec(email)
    const companyRoot = (regExArr && regExArr[1]) || email
    return companyRoot
}

export const getClientWithHooks = ({ setHasInput }, searchClient) => {
    const searchClientWithCustomSearch = {
        search(requests) {
            let hasQuery = false
            let query = ''
            requests.forEach((request) => {
                query = request.params.query
                const isEmptyQuery = query === ''
                if (!isEmptyQuery) hasQuery = true
            })
            setHasInput(hasQuery)
            return searchClient.search(requests)
        }
    }

    return searchClientWithCustomSearch
}

export const getConversationPeople = async (conversations, getAlgoliaData) => {
    const peopleIds = []
    conversations.forEach((conversation) => peopleIds.push(...conversation.people))
    const people = await getAlgoliaData(peopleIds)

    return people.reduce((map, person) => {
        const objectID = person.objectID
        map[objectID] = person
        return map
    }, {})
}

export const extractCardDataByRecordType = (record) => {
    if (record.type === 'company') {
        // shared
        const Icon = BusinessIcon
        const title = record.name || record.companyRoot || '' // on person, the title is the person's name
        const site = record.companyRoot || ''
        const highlights = record._highlightResult || {}
        const tags = record.tags || []
        const recordTypeMatches = ['tags', 'name', 'companyRoot']
        const companyIds = record.companies || []
        const type = record.type || ''
        // look-up Ids
        const conversationIds = record.conversations || []
        const peopleIds = record.people || []
        const relationshipIds = []

        return {
            Icon,
            title,
            site,
            highlights,
            tags,
            conversationIds,
            peopleIds,
            recordTypeMatches,
            companyIds,
            relationshipIds,
            type
        }
    }

    if (record.type === 'person') {
        const name = record.person?.name || ''
        const personId = record.objectID || ''
        const companyId = record.company
        const companyInfo = record.person?.company || {}
        const emailMeta = record.emailMeta || {}
        const online = record.online || {}
        const phone = record.phone || {}
        const type = record.type || ''
        const tags = record.tags || []
        const companyLookup = record.company

        const recordTypeMatches = [
            'tags',
            'person.name',
            'person.company.companyName',
            'person.online.email'
        ]

        // look-up Ids
        const conversationIds = record.conversations || []
        const relationshipIds = record.relationships || []

        // shared
        const Icon = PersonIcon
        const title = (record && record.person && record.person.name) || '' // on person, the title is the person's name
        const email =
            (record && record.person && record.person.online && record.person.online.email) || '' // email or site
        const highlights = record._highlightResult || {}
        const peopleIds = []

        return {
            name,
            companyId,
            companyInfo,
            emailMeta,
            online,
            phone,
            type,
            conversationIds,
            relationshipIds,
            Icon,
            title,
            email,
            highlights,
            tags,
            peopleIds,
            personId,
            recordTypeMatches,
            companyLookup
        }
    }

    if (record.type === 'conversation') {
        const getConversationFolderPath = (record) => {
            if (
                record.folderData &&
                record.folderData.length > 0 &&
                record.parentFolderData &&
                record.parentFolderData.length > 0
            ) {
                return `${record.parentFolderData[0].name}`
            }
            return ''
        }

        const recordTypeMatches = ['tags', 'content']
        // look-up Ids
        const companyIds = record.companies || []
        const peopleIds = record.people || []
        const relationshipIds = []

        // shared
        const Icon = ForumIcon
        const title =
            record.identifier ||
            record.companyTag ||
            record.folderName ||
            getConversationFolderPath(record)
        const highlights = record._highlightResult || []
        const tags = record.tags || []
        const type = record.type || ''

        return {
            Icon,
            title,
            highlights,
            tags,
            companyIds,
            peopleIds,
            recordTypeMatches,
            relationshipIds,
            type
        }
    }

    return null
}

export const segregateTypes = (records) =>
    records.reduce((map, record) => {
        const recordType = record.type || ''
        const recordsOfOneType = map[recordType] || []
        recordsOfOneType.push(record)
        map[recordType] = recordsOfOneType
        return map
    }, {})

export const getMatches = (recordTypeMatches, highlights) =>
    recordTypeMatches.reduce(
        (hasMatchByAttribute, attributeKey) => {
            const splitKey = attributeKey.split('.')
            const isDeepKey = splitKey.length > 1
            const deepKeys = [...splitKey].reverse()

            const matchesForSearchAttribute = isDeepKey
                ? accessDeepObjects(highlights, deepKeys)
                : highlights[attributeKey] || {}

            const hasMatch = Array.isArray(matchesForSearchAttribute)
                ? matchesForSearchAttribute?.some((attribute) => attribute.matchedWords?.length)
                : !!matchesForSearchAttribute.matchedWords?.length

            hasMatchByAttribute[attributeKey] = hasMatch
            if (hasMatch) hasMatchByAttribute.count += 1

            return hasMatchByAttribute
        },
        { count: 0 }
    )

const isAnObject = (val) => {
    if (val === null) return false
    return typeof val === 'function' || typeof val === 'object'
}

export const accessDeepObjects = (object, arrayKeys) => {
    // Currently only goes deep on objects, returns arrays in entirety
    const keys = [...arrayKeys]
    const currentKey = keys.pop()
    const currentValue = object[currentKey] || {}
    const isObject = isAnObject(currentValue)

    if (!isObject || keys.length === 0) return { ...currentValue }

    return accessDeepObjects({ ...currentValue }, [...keys])
}

const identifyTagDelta = (oldTags, newTags) => {
    const oldMap = {}
    const newMap = {}
    const added = []
    const deleted = []

    oldTags.forEach((tag) => (oldMap[tag] = true))
    newTags.forEach((tag) => {
        newMap[tag] = true
        const isAdded = !oldMap.hasOwnProperty(tag)
        if (isAdded) {
            added.push(tag)
        }
    })
    oldTags.forEach((tag) => {
        const isDeleted = !newMap.hasOwnProperty(tag)
        if (isDeleted) {
            deleted.push(tag)
        }
    })

    return { added, deleted }
}

const generateTags = (tags, objectID, operationType) => {
    const operation = operationType === 'delete' ? 'Remove' : 'AddUnique'
    return tags.map((tag) => ({
        objectID,
        tags: {
            _operation: operation,
            value: tag
        }
    }))
}

export const updateTags = async (oldTags, newTags, objectID) => {
    const { added, deleted } = identifyTagDelta(oldTags, newTags)
    const adds = generateTags(added, objectID, 'add')
    const deletes = generateTags(deleted, objectID, 'delete')
    const response = await updateRecords([...deletes, ...adds])
    return response
}

export const stripUserTags = (tagsOnRecord, userTag) => {
    return tagsOnRecord.filter((tag) => {
        return tag.trim() !== userTag
    })
}

export const identifyIdsWithoutRecords = (missingIdsRequested, algoliaResponse) => {
    const returnedRecords = []
    const idsRequested = {}
    missingIdsRequested.forEach((id) => (idsRequested[id] = true))
    algoliaResponse.forEach((record) => {
        if (record) {
            const id = record.objectID
            delete idsRequested[id]
            returnedRecords.push(record)
        }
    })
    const idsNotReturned = Object.keys(idsRequested)
    return { idsNotReturned, returnedRecords }
}

export const generateMissingObject = (objectID) => ({
    type: 'missing',
    objectID
})

export const createEmptyRecords = (objectID) => {
    return {
        objectID,
        person: {
            online: {
                email: 'Missing Record'
            }
        }
    }
}
// table functions

export const extractTableDataFromPeople = (
    people = [],
    teamMembers = [],
    engagementConfig = []
) => {
    const personMap = {}
    const rowsOfData = people.map((person, index) => {
        const objectID = person.objectID
        const closestContactEmail = person.person?.emailMeta?.closestInternalContact
        const member = teamMembers?.find((member) =>
            member.allEmailAddresses.includes(closestContactEmail)
        )
        const closestInternalContactName = member ? member.name : closestContactEmail
        const lastInteraction = person.person?.emailMeta?.lastInteraction
        const lastInteractionDate = formatUnixDate(lastInteraction, 'MMM d, yyyy') || ''
        if (objectID) personMap[objectID] = person
        return {
            objectID,
            id: index + 1,
            name: person.person?.name || 'click to open',
            email: person.person?.online?.email || '',
            title: person.person?.company?.title || '',
            externalMeetings: person.person?.emailMeta?.externalMeetings || '',
            directInboundEmails: person.person?.emailMeta?.directInboundEmails || '',
            directOutboundEmails: person.person?.emailMeta?.directOutboundEmails || '',
            closestInternalContact: closestInternalContactName || '',
            ccEmails: person.person?.emailMeta?.ccEmails || '',
            lastInteraction: lastInteractionDate,
            relationshipStatus: clasifyByInteractions(lastInteraction, engagementConfig)
        }
    })

    return { personMap, rowsOfData }
}

export const getPeopleOnCompanyColumns = (personMap, onClickSetPreview) => [
    {
        headerClassName: 'tableHeader',
        field: 'name',
        headerName: 'Name',
        width: 200,
        renderCell: (params) => {
            const objectID = params.row.objectID
            const record = personMap[objectID]
            const onClick = (e) => {
                onClickSetPreview(e, record)
            }

            return (
                <div className={'linkTextDark'} onClick={onClick}>
                    {params.row.name}
                </div>
            )
        }
    },
    {
        headerClassName: 'tableHeader',
        field: 'title',
        headerName: 'Title',
        width: 200
    },
    {
        headerClassName: 'tableHeader',
        field: 'email',
        headerName: 'Email',
        width: 200
    },
    {
        headerClassName: 'tableHeader',

        field: 'lastInteraction',
        headerName: 'Last Interaction',
        width: 200
    },
    {
        headerClassName: 'tableHeader',
        field: 'directInboundEmails',
        headerName: 'Direct emails inbound',
        decription: 'Direct emails recieved from Greylock',
        width: 150
    },
    {
        headerClassName: 'tableHeader',
        field: 'externalMeetings',
        headerName: 'Meetings',
        width: 150
    },
    {
        headerClassName: 'tableHeader',
        field: 'closestInternalContact',
        headerName: 'Strongest Internal Relationship',
        decription: 'Strongest internal relationship person at Greylock',
        width: 200
    },
    {
        headerClassName: 'tableHeader',
        field: 'relationshipStatus',
        headerName: 'Relationship Status',
        decription: 'Relationship status clasification based on the last interaction date',
        width: 200
    }
]

export const extractTableDataFromConversations = (conversations = []) => {
    const conversationMap = {}
    const rowsOfData = conversations.map((conversation, index) => {
        conversationMap[conversation.objectID] = conversation
        return {
            objectID: conversation.objectID,
            id: index + 1,
            name: conversation.name || '',
            people: conversation.people || ''
        }
    })

    return { conversationMap, rowsOfData }
}

export const getConversationsOnCompanyColumns = (
    conversationMap,
    onClickSetPreview,
    getRecordsByIdAndType,
    peopleOnConversation
) => [
    {
        headerClassName: 'tableHeader',
        field: 'name',
        headerName: 'Name',
        width: 200,
        renderCell: (params) => {
            const objectID = params.row.objectID
            const record = conversationMap[objectID]
            const onClick = (e) => {
                onClickSetPreview(e, record)
            }

            return (
                <div className={'linkTextDark'} onClick={onClick}>
                    {params.row.name}
                </div>
            )
        }
    },
    {
        headerClassName: 'tableHeader',
        field: 'people',
        headerName: 'People in conversation',
        width: 600,
        renderCell: (params) => {
            return params.row.people.map((objectID, i, a) => {
                const person = peopleOnConversation[objectID] || {}
                const value = person?.person?.online?.email
                    ? person.person.online.email
                    : 'Incomplete Record'

                return (
                    <div
                        className={'linkTextDark'}
                        onClick={(e) => onClickSetPreview(e, person)}
                        style={{ margin: '0 3px 0 0' }}
                    >
                        {value}
                        {i === a.length - 1 ? '' : ','}
                    </div>
                )
            })
        }
    }
]

export const extractTableDataFromCompanies = (companies = []) => {
    const companyMap = {}
    const rowsOfData = companies.map((company, index) => {
        companyMap[company.objectID] = company
        return {
            objectID: company.objectID,
            id: index + 1,
            companyName: company.name || company.companyRoot || '',
            peopleOnRecord: company.people?.length || '',
            conversationsOnFile: company.conversations?.length || ''
        }
    })

    return { companyMap, rowsOfData }
}

export const getCompaniesColumns = (companyMap, onClickSetPreview, getRecordsByIdAndType) => [
    {
        headerClassName: 'tableHeader',
        field: 'companyName',
        headerName: 'Name',
        width: 400,
        renderCell: (params) => {
            const objectID = params.row.objectID
            const record = companyMap[objectID]
            const onClick = (e) => {
                onClickSetPreview(e, record)
            }

            return (
                <div className={'linkTextDark'} onClick={onClick}>
                    {params.row.companyName}
                </div>
            )
        }
    },

    {
        headerClassName: 'tableHeader',
        field: 'peopleOnRecord',
        headerName: 'Employees Contacted',
        width: 200
    },

    {
        headerClassName: 'tableHeader',
        field: 'conversationsOnFile',
        headerName: 'Conversations Had',
        width: 200
    }
]

// sig parser

export const extractTableDataFromSigParserEmails = (emailHeaders) => {
    const emailMap = {}

    const rowsOfData = emailHeaders.map((emailHeader, index) => {
        const id = index + 1
        emailMap[id] = emailHeader
        return {
            id,
            date: emailHeader.date,
            subject: emailHeader.subject,
            from: emailHeader.from.name,
            to:
                emailHeader.to && emailHeader.to.length
                    ? emailHeader.to.map((i) => i.name).join(', ')
                    : '',
            cc:
                emailHeader.cc && emailHeader.cc.length
                    ? emailHeader.cc.map((i) => i.name).join(', ')
                    : '',
            attachments: emailHeader.attachments
        }
    })

    return { emailMap, rowsOfData }
}

export const formatUnixDate = (unix, formatString) => {
    if (!unix) return null
    const date = fromUnixTime(unix)
    return format(date, formatString)
}

export const getSigParserEmailsColumns = () => [
    {
        headerClassName: 'tableHeader',
        field: 'date',
        headerName: 'Date',
        width: 200,
        renderCell: (params) => {
            const dateString = params.formattedValue
            const date = parseJSON(dateString)
            const formattedDate = format(date, 'h:mm aaa, MM-dd-yy')
            return <>{formattedDate}</>
        }
    },
    {
        headerClassName: 'tableHeader',
        field: 'subject',
        headerName: 'Subject',
        width: 200
    },
    {
        headerClassName: 'tableHeader',
        field: 'from',
        headerName: 'From',
        width: 200,
        renderCell: (params) => {
            const toCombos = [params.formattedValue] || []
            return toCombos.map((combo) => <>{combo}</>)
        }
    },
    {
        headerClassName: 'tableHeader',
        field: 'to',
        headerName: 'To',
        width: 200,
        renderCell: (params) => {
            const toCombos = [params.formattedValue] || []
            return toCombos.map((combo) => <>{combo}</>)
        }
    },

    {
        headerClassName: 'tableHeader',
        field: 'cc',
        headerName: 'CC',
        width: 200,
        renderCell: (params) => {
            const toCombos = [params.formattedValue] || []
            return toCombos.map((combo) => <>{combo}</>)
        }
    },
    {
        headerClassName: 'tableHeader',
        field: 'attachments',
        headerName: 'Attachments',
        width: 200,
        renderCell: (params) => {
            const attachments = params?.row?.attachments || []
            const fileNames = attachments.map((attachment) => {
                return attachment.filename || ''
            })
            const uniqueStrings = [...new Set(fileNames)].join(', ')
            return <>{uniqueStrings}</>
        }
    }
]

export const extractTableDataFromSigParserMeetings = (meetingHeaders = []) => {
    const meetingMap = {}
    const rowsOfData = meetingHeaders.map((meetingHeader, index) => {
        const id = index + 1
        meetingMap[id] = meetingHeader
        return {
            id,
            date: meetingHeader.start,
            subject: meetingHeader.subject,
            organizer: meetingHeader.organizer,
            accepted: meetingHeader.attendees.filter((attendee) => !attendee.declined),
            declined: meetingHeader.attendees.filter((attendee) => attendee.declined),
            isCancelled: meetingHeader.cancelled
        }
    })

    return { meetingMap, rowsOfData }
}

export const getSigParserMeetingsColumns = () => [
    {
        headerClassName: 'tableHeader',
        field: 'date',
        headerName: 'Date',
        width: 150,
        renderCell: (params) => {
            const dateString = params.formattedValue
            const date = parseJSON(dateString)
            const formattedDate = format(utcToZonedTime(date, 'Los Angeles'), 'MM-dd-yy')
            return <>{formattedDate}</>
        }
    },
    {
        headerClassName: 'tableHeader',
        field: 'subject',
        headerName: 'Subject',
        width: 250
    },
    {
        headerClassName: 'tableHeader',
        field: 'organizer',
        headerName: 'Organizer',
        width: 200
    },
    {
        headerClassName: 'tableHeader',
        field: 'accepted',
        headerName: 'Accepted',
        width: 600,
        renderCell: (params) => {
            const accepted = params.formattedValue
            return accepted.map((acceptee, index) => (
                <>
                    {acceptee.email}
                    {index === accepted.length - 1 ? null : ', '}
                </>
            ))
        }
    },
    {
        headerClassName: 'tableHeader',
        field: 'declined',
        headerName: 'Declined',
        width: 200,
        renderCell: (params) => {
            const declined = params.formattedValue
            return declined.map((declinee) => <>{declinee.email}</>)
        }
    }
]

//mapping features

export const accumulateRelationshipMetrics = (teamMembers, relationShipTable) => {
    var reconciledRelationship = {}

    relationShipTable.forEach((owner) => {
        const closestContactEmail = owner.email
        const member = teamMembers?.find((member) =>
            member.allEmailAddresses.includes(closestContactEmail)
        )
        const closestInternalContactName = member ? member.name : closestContactEmail
        var hasOwner = reconciledRelationship.hasOwnProperty(closestInternalContactName)
            ? true
            : false
        var newOwner = {}
        Object.assign(newOwner, owner)
        newOwner['name'] = closestInternalContactName
        if (hasOwner) {
            newOwner.emailsCount =
                owner.emailsCount + reconciledRelationship[closestInternalContactName].emailsCount
            newOwner.meetingsCount =
                owner.meetingsCount +
                reconciledRelationship[closestInternalContactName].meetingsCount
            newOwner.totalInteractions =
                owner.totalInteractions +
                reconciledRelationship[closestInternalContactName].totalInteractions
        }
        reconciledRelationship[closestInternalContactName] = newOwner
    })

    return Object.values(reconciledRelationship)
}

export const clasifyByInteractions = (lastInteraction, engagementConfig) => {
    var classification = geRelationShipStatus(lastInteraction, engagementConfig)
    return classification
}

const geRelationShipStatus = (lastInteractionDate, engagementConfig) => {
    const comparissionMethod = (countIn, date) =>
        engagementConfig.compareIn === 'months'
            ? compareDateInMonthlyPeriod(countIn, date)
            : compareDateInWeeklyPeriod(countIn, date)
    let clasification = engagementConfig.relationshipStatus.reduce((prev, current, index) => {
        const isAfterStatusRequires = comparissionMethod(
            current.lastInteractionIn,
            lastInteractionDate
        )
        const isAfterStatusOrLastInteractionNull =
            lastInteractionDate && (isAfterStatusRequires || current.lastInteractionIn === -1)
        if (isAfterStatusOrLastInteractionNull) {
            return current
        } else {
            return prev
        }
    })

    return clasification.name
}

const moment = require('moment')

const compareDateInMonthlyPeriod = (monthsBefore, dateToCompare) => {
    var monthsAgoDate = moment().subtract(monthsBefore, 'months')
    var comparisonDate = moment(fromUnixTime(dateToCompare))

    return moment(comparisonDate).isAfter(monthsAgoDate)
}

const compareDateInWeeklyPeriod = (weeksBefore, dateToCompare) => {
    var weeksAgoDate = moment().subtract(weeksBefore, 'weeks')
    var comparissionDate = moment(fromUnixTime(dateToCompare))

    return moment(comparissionDate).isAfter(weeksAgoDate)
}
